<template>
  <div class="user-view-dialog">
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          :title="user.name + ' - ' + user.email"
          closeBtn
          :closeAction="editUserCancel"
          :btnOptions="[
            {
              name: '',
              icon: 'mdi-check',
              action: this.editUserSave,
              btnColor: 'white',
            },
          ]" />
        <v-card-text>
          <v-form ref="form" @submit.prevent="editUserSave" class="ma-2">
            <!-- eslint-disable vue/no-mutating-props -->
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    id="email"
                    v-model="user.email"
                    label="Email Address"
                    readonly
                    disabled></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    id="name"
                    v-model="user.name"
                    label="Name"
                    :rules="validate_name"
                    :readonly="!editMode"
                    :disabled="!editMode"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    id="phone"
                    v-model="user.phone"
                    label="Phone Number"
                    :rules="validate_user_phone"
                    :readonly="!editMode"
                    :disabled="!editMode"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="user.role"
                    :items="roleList"
                    filled
                    label="Role"
                    :rules="validate_required"
                    :readonly="!editMode"
                    :disabled="!editMode"></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="user.status"
                    :items="selectItems"
                    filled
                    label="Active"
                    :rules="validate_required"
                    :readonly="!editMode"
                    :disabled="!editMode"></v-select>
                </v-col>
              </v-row>
            </v-container>
            <!-- eslint-enable -->
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snackbarText }}
      <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
    <error-dialog v-model="errorDialog" :header="errorHeader" :body="errorBody">
    </error-dialog>
  </div>
</template>

<script>
  import Forms from '@/mixins/forms'; //form validation
  import ErrorDialog from '@/components/helpers/ErrorDialog'; //dialog for displaying errors
  import HeaderView from '../header-view';
  import Users from '@/services/Users.service.js';

  export default {
    name: 'UserViewDialog',
    components: {
      'error-dialog': ErrorDialog,
      'header-view': HeaderView,
    },
    mixins: [Forms],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      user: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        editMode: true,
        busy: false,
        snackbar: false,
        snackbarText: '',
        dialog: false,
        errorDialog: false,
        errorHeader: '',
        errorBody: '',
        emailUniqueError: false,
        emailUniqueMessage: '',
        selectItems: ['Active', 'Inactive'],
        roles: [
          {
            name: 'System Admin',
            uuid: 'system_admin',
          },
          {
            name: 'Admin',
            uuid: 'admin',
          },
          {
            name: 'Crew',
            uuid: 'crew',
          },
        ],
        password_verify: '',
        passwords_match: [
          () => this.validatePasswordsMatch() || 'Passwords do not match',
        ],
      };
    },
    computed: {
      roleList: function () {
        let list = [];
        this.roles.forEach((role) => {
          var myrole = this.$auth.userProfile.role;
          if (role.uuid == 'system_admin' && myrole != 'system_admin') {
            //only allow system admins to add another system admin
          } else {
            list.push({
              text: role.name,
              value: role.uuid,
            });
          }
        });
        // `this` points to the vm instance
        return list;
      },
    },
    watch: {
      value: function () {
        if (this.dialog != this.value) {
          this.dialog = this.value;
        }
      },
      dialog: function (after, before) {
        if (before != after) {
          this.$emit('input', this.dialog);
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      validatePasswordsMatch() {
        if (this.password_verify === this.user.password) {
          return true;
        } else {
          return false;
        }
      },
      async editUserSave() {
        //disable save and cancel button while processing
        this.busy = true;
        if (!this.$refs.form.validate() && !this.emailUniqueError) {
          //if form doesn't validate, do nothing, validation info will be displayed in the form
          this.busy = false;
          return;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        const success = await Users.updateUser(
          this.user.uuid,
          this.user,
          accessToken
        );

        if (success == true) {
          // console.log( yay )
          this.snackbarText = 'User ' + this.user.email + ' saved successfully';
          this.snackbar = true;
          this.$refs.form.resetValidation();
          this.dialog = false;
          this.$emit('save');
          this.busy = false;
        } else {
          this.errorHeader = 'Error';
          this.errorBody =
            'There was a server error while creating this user, please contact support';
          this.errorDialog = true;
        }
      },
      async editUserCancel() {
        //clear the current user info
        this.$refs.form.resetValidation();
        this.dialog = false;
        this.$emit('cancel');
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
