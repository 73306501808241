<template>
  <div class="user-list-component">
    <header-view title="Users" :btnOptions="addUserButton" />
    <main-container>
      <v-card>
        <v-card-title>
          <v-btn
            height="28"
            class="button-primary"
            @click="filterdialog = true">
            <span>Filter</span>
            <v-icon right small>mdi-filter</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="QuickSearch"
            single-line
            hide-details></v-text-field>
        </v-card-title>
        <div>
          <v-data-table
            :headers="headers"
            :items="filteredUsers"
            class="elevation-1"
            :sort-by="['name']"
            :sort-desc="[false]"
            :search="search"
            :loading="busy">
            <template v-slot:item="props">
              <tr @click="viewUser(props.item)">
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.email }}</td>
                <td>{{ props.item.role }}</td>
                <td class="text-center">
                  <table-status :status="props.item.status" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </main-container>
    <v-dialog v-model="filterdialog" persistent max-width="600px">
      <v-card>
        <header-view dialog previous_page="NO_BACK_BUTTON" />
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  label="Name Contains"
                  v-model="name"
                  hint=""
                  required></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  label="Email Contains"
                  v-model="email"
                  hint=""
                  required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <div id="client-status-filter">
                  <v-select
                    v-model="status"
                    :items="filterItems"
                    filled
                    label="Status"></v-select>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="button-primary" flat @click="applyFilter()"
            >Apply</v-btn
          >
          <v-btn class="button-secondary" flat @click="clearFilter()"
            >Clear</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <user-view-dialog
      v-model="viewDialog"
      :user="user"
      @save="reloadUsers"
      @cancel="reloadUsers"
      @close="reloadUsers">
    </user-view-dialog>
    <user-create-dialog
      v-model="addDialog"
      @save="reloadUsers"
      v-on:close-dialog="closeDialog">
    </user-create-dialog>
  </div>
</template>

<script>
  import UserCreateDialog from '@/components/users/user-create-dialog';
  import UserViewDialog from '@/components/users/user-view-dialog';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import TableStatus from '@/components/table-status';
  import Users from '@/services/Users.service.js';

  export default {
    name: 'AppUserList',
    components: {
      TableStatus,
      'header-view': HeaderView,
      'main-container': MainContainer,
      'user-create-dialog': UserCreateDialog,
      'user-view-dialog': UserViewDialog,
    },
    mixins: [],
    props: {},
    data() {
      return {
        addDialog: false,
        editDialog: false,
        viewDialog: false,
        filterdialog: false,
        name: '',
        email: '',
        users: [],
        user: {},
        status: 'Active',
        filterItems: [
          {
            text: 'Show All',
            value: '',
          },
          {
            text: 'Active Only',
            value: 'Active',
          },
          {
            text: 'Inactive Only',
            value: 'Inactive',
          },
        ],
        search: '',
        busy: false,
        headers: [
          {
            text: 'Name',
            align: 'left',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Email address',
            value: 'email',
          },
          {
            text: 'Role',
            value: 'role',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
      };
    },
    computed: {
      // a computed getter
      filteredUsers: function () {
        // `this` points to the vm instance
        return this.users.filter((user) => {
          let pass = true;
          if (this.name && !user.name.includes(this.name)) {
            pass = false;
          }
          if (this.email && !user.email.includes(this.email)) {
            pass = false;
          }
          if (this.status && user.status != this.status) {
            pass = false;
          }
          return pass;
        });
      },
      activeUsers: function () {
        return this.users.filter((user) => {
          let pass = false;
          if (user.status == 'Active') {
            pass = true;
          }
          return pass;
        });
      },
      maxUsers() {
        var allowed = 0;
        var tenantProfile = this.$auth.tenantProfile;
        if (tenantProfile.subscription_type) {
          var subscription = tenantProfile.subscription_type.toLowerCase();
          if (subscription == '3user') {
            allowed = 3;
          } else if (subscription == '10user') {
            allowed = 10;
          } else if (subscription == 'unlimited' || subscription == 'free') {
            allowed = 1000;
          }
        } else {
          console.log('tenant subscription type is not defined!');
        }

        if (this.activeUsers.length >= allowed) {
          return true;
        } else {
          return false;
        }
      },
      addUserButton: function () {
        var b = {
          name: this.maxUsers ? 'Max Users Reached' : 'New User',
          icon: this.maxUsers ? 'mdi-account-alert-outline' : 'mdi-plus',
          action: this.maxUsers
            ? () => {
                return null;
              }
            : () => this.addDialogOpen(),
        };
        return [b];
      },
    },
    async mounted() {
      this.busy = true;

      if (!this.$auth.userProfile.tenant_uuid) {
        console.log('could not get tenant uuid, aborting...');
        return;
      }

      // get access token
      const accessToken = await this.$auth.getTokenSilently();

      // get users
      var res = await Users.getUsersByTenant(
        this.$auth.userProfile.tenant_uuid,
        accessToken
      );

      // validate
      if (res) {
        this.users = res;
      } else {
        console.log('failed to load users!');
      }

      this.busy = false;
    },
    methods: {
      clearFilter: function () {
        this.name = '';
        this.email = '';
        this.status = 'Active';
        this.filterdialog = false;
      },
      applyFilter: function () {
        this.filterdialog = false;
        this.filteredUsers();
      },
      addDialogOpen: function () {
        this.addDialog = true;
      },
      reloadUsers: async function () {
        // get access token
        const accessToken = await this.$auth.getTokenSilently();

        var res = await Users.getUsersByTenant(
          this.$auth.userProfile.tenant_uuid,
          accessToken
        );

        if (res) {
          this.users = res;
        } else {
          console.log('failed to load users!');
        }
      },
      viewUser: function (user) {
        this.user = user;
        this.viewDialog = true;
      },

      closeDialog: function () {
        // console.log( 'close dialog 2' );
        this.addDialog = false;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #filter-select {
    height: 60px;
    width: 100px;
    margin: auto;
  }
</style>
