<template>
  <div>
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          title="Add User"
          closeBtn
          :closeAction="createUserCancel"
          :btnOptions="[
            {
              name: '',
              icon: 'mdi-check',
              action: this.createUserSave,
              btnColor: 'white',
              disabled:
                !(password && password === password2) ||
                !email ||
                !name ||
                !role ||
                !status,
            },
          ]" />
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    ref="email_field"
                    v-model="email"
                    label="Email Address"
                    :rules="emailRules"
                    :error="emailUniqueError"
                    :error-messages="emailUniqueMessage"
                    v-on:blur="handleBlur"
                    required>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    id="name"
                    v-model="name"
                    label="Name"
                    :rules="validate_name" />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    id="phone"
                    v-model="phone"
                    label="Phone Number"
                    :rules="validate_user_phone" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    id="role"
                    v-model="role"
                    :items="roleList"
                    filled
                    label="Role"
                    :rules="validate_required" />
                </v-col>
                <v-col cols="6">
                  <v-select
                    id="status"
                    v-model="status"
                    :items="selectItems"
                    filled
                    label="Status"
                    :rules="validate_required"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="password"
                    :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[passwordRules.required, passwordRules.regex]"
                    :type="showPass1 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Enter Password"
                    hint="Enter your password"
                    class="input-group--focused"
                    @click:append="showPass1 = !showPass1"
                    autocomplete="password">
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="password2"
                    :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[
                      passwordRules.required,
                      passwordRules.regex,
                      passwordsmatch,
                    ]"
                    :type="showPass2 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Confirm Password"
                    hint="Confirm your password"
                    @click:append="showPass2 = !showPass2"
                    autocomplete="password">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snackbarText }}
      <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
    <error-dialog v-model="errorDialog" :header="errorHeader" :body="errorBody">
    </error-dialog>
  </div>
</template>

<script>
  import Forms from '@/mixins/forms'; //form validation
  import ErrorDialog from '@/components/helpers/ErrorDialog'; //dialog for displaying errors
  import HeaderView from '../header-view';
  import Users from '@/services/Users.service.js';

  export default {
    name: 'UserCreateDialog',
    components: {
      'error-dialog': ErrorDialog,
      'header-view': HeaderView,
    },
    mixins: [Forms],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        valid: false,
        firstname: '',
        lastname: '',
        nameRules: [
          (v) => !!v || 'Name is required',
          (v) =>
            (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        email: '',
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        showPass1: false,
        showPass2: false,
        password: '',
        password2: '',
        passwordRules: {
          required: (value) => !!value || 'Password is required',
          regex: (v) => {
            // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
            // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
            if (
              !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                v
              )
            ) {
              return 'Password must have a minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
            }
            return true;
          },
        },
        name: '',
        status: '',
        role: '',
        phone: '',
        busy: false,
        snackbar: false,
        snackbarText: '',
        dialog: false,
        errorDialog: false,
        errorHeader: '',
        errorBody: '',
        emailUniqueError: false,
        emailUniqueMessage: '',
        selectItems: ['Active', 'Inactive'],
        roles: [
          {
            name: 'System Admin',
            uuid: 'system_admin',
          },
          {
            name: 'Admin',
            uuid: 'admin',
          },
          {
            name: 'Crew',
            uuid: 'crew',
          },
        ],
      };
    },
    computed: {
      passwordsMatch: function () {
        return (
          (this.password && this.password === this.password2) ||
          'The passwords must match'
        );
      },
      roleList: function () {
        let list = [];
        this.roles.forEach((role) => {
          var myrole = this.$auth.userProfile.role;
          if (role.uuid == 'system_admin' && myrole != 'system_admin') {
            //only allow system admins to add another system admin
          } else {
            list.push({
              text: role.name,
              value: role.uuid,
            });
          }
        });
        // `this` points to the vm instance
        return list;
      },
    },
    watch: {
      value: function () {
        if (this.dialog != this.value) {
          this.dialog = this.value;
        }
      },
      dialog: function (after, before) {
        if (before != after) {
          this.$emit('input', this.dialog);
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async handleBlur() {
        // this will clear out email in use message if
        // the field was cleared. Email required error will stil display
        if (this.email == null || this.email == '') {
          this.emailUniqueError = false;
          this.emailUniqueMessage = '';
          return;
        }

        if (this.email && this.email.length > 0) {
          // if field has errors OTHER THAN the unique email check error,
          // don't bother running it against database as it doesn't meet validation
          // requirements
          if (
            this.emailUniqueError == false &&
            this.$refs.email_field &&
            this.$refs.email_field.hasError
          ) {
            return;
          }

          // find out if email is already taken
          var matchedUsers = await Users.checkIfEmailIsTaken(this.email);

          // if taken, update input field with custom error
          if (matchedUsers) {
            this.emailUniqueError = true;
            this.emailUniqueMessage =
              'This address is already in use by another user';
          } else {
            this.emailUniqueError = false;
            this.emailUniqueMessage = '';
          }
        }
      },
      async createUserSave() {
        //disable save and cancel button while processing
        this.busy = true;
        if (!this.$refs.form.validate() || this.emailUniqueError) {
          //if form doesn't validate, do nothing, validation info will be displayed in the form
          this.busy = false;
          return;
        }

        // set up new user data
        var user = {
          tenant_uuid: this.$auth.userProfile.tenant_uuid,
          role: this.role,
          name: this.name,
          email: this.email.toLowerCase(),
          password: this.password,
          phone: this.phone,
          status: 'Active',
          submitted: true,
        };

        // create user
        // note: no accessToken is needed, as this route must remain unsecured
        // for onboarding.
        var success = await Users.createUser(user);

        if (success) {
          this.snackbarText = 'User ' + this.email + ' added successfully';
          this.snackbar = true;
          this.$refs.form.reset();
          this.$emit('save');
          this.$emit('close-dialog');
          this.busy = false;
        } else {
          this.errorHeader = 'Error';
          this.errorBody =
            'There was a server error while creating this user, please contact support';
          this.errorDialog = true;
        }
      },
      createUserCancel() {
        //clear the current user info
        // this.user = this.blankUser()
        //
        // this.$refs.form.resetValidation();
        this.$refs.form.reset();
        // this.dialog = false
        this.$emit('close-dialog');
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
